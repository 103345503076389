import { BlurEvent, ChangeEvent, ClickEvent, DataDisplayEvent, DataSource, DataSourceMode, DropdownItem, Table, TableRow, Textbox, ValidationResult } from "@mcleod/components";
import { CityUtil, FieldUpdateEvent, Model, ModelRow, PermissionsUtil, Timezone, getAuthSettings, getLogger } from "@mcleod/core";
import { isApptConfirmed, showAppointmentChangeDialog } from "@mcleod/dispatch/src/AppointmentUtil";
import { makeTooltipCallbackFunction } from "@mcleod/dispatch/src/LocationMakeQuickInfo";
import { RowLocation } from "@mcleod/dispatch/src/models/ModelLocation";
import { ModelContact } from "@mcleod/general/src/models/ModelContact";
import { getDispatchControlBoolean } from "@mcleod/general/src/models/ModelDispatchControl";
import { EdiStopNotesAndReferenceNumbers } from "./EdiStopNotesAndReferenceNumbers";
import { AutogenLayoutEdiStop } from "./autogen/AutogenLayoutEdiStop";
import { EdiUtility } from "./EdiUtility";

const log = getLogger("lme.datafusion.ViewEdiOrder");

const DATE_ON = { caption: "On", value: "O" };
const DATE_BETWEEN = { caption: "Between", value: "B" };

export class EdiStop extends AutogenLayoutEdiStop {
    private enforceShipper: boolean = getAuthSettings().dispatch_control[0].enforce_shipper_id == "Y";
    commentsAndRefNbrs: EdiStopNotesAndReferenceNumbers;
    private _validateApptChanges: boolean = false;
    private _loadingRecurringOrder: boolean = false;
    private _slideOutTableRow: TableRow;
    private _profileId: string;

    public calculateRates: () => void;

    public get validateApptChanges(): boolean {
        return this._validateApptChanges;
    }
    public set validateApptChanges(value: boolean) {
        this._validateApptChanges = value;
    }

    public get loadingRecurringOrder(): boolean {
        return this._loadingRecurringOrder;
    }
    public set loadingRecurringOrder(value: boolean) {
        this._loadingRecurringOrder = value;
    }

    public get slideOutTableRow(): TableRow {
        return this._slideOutTableRow;
    }
    public set slideOutTableRow(value: TableRow) {
        this._slideOutTableRow = value;
    }

    public get stopDateTypeBetween(): DropdownItem { return DATE_BETWEEN }
    public get stopDateTypeOn(): DropdownItem { return DATE_ON }
    private _orderSource: DataSource;
    private _stopSource: DataSource;

    override onLoad(): void {
        this.commentsAndRefNbrs = this.layoutStopNotesAndRefNbrs as EdiStopNotesAndReferenceNumbers;
        this.textboxStopDateType.items = [DATE_ON, DATE_BETWEEN];
        this.textboxStopDateType.selectedItem = DATE_BETWEEN;
        this.textboxSchedArriveLate.required = true;
        //this.textboxSchedArriveEarly.addlValidationCallback = () => this.scheduleArriveValidator();
        this.textboxContactName.onSelectItem = ((textbox, selection) => {
            this.sourceEdiStop.activeRow.set("phone", (selection as ModelRow).get("phone"));
            this.sourceEdiStop.activeRow.set("contact_email", (selection as ModelRow).get("email"));
            this.sourceEdiStop.displayDataInBoundComponents()
            return undefined;
        })
        this.textboxLocationId.onSelectItem = ((textbox, selection) => {
            this.setLocationTextAfterLocation(textbox, selection as ModelRow);
            const location_id = this.mainDataSource.activeRow?.get("location_id")
            if (location_id) {
                Model.searchSingleRecord("lme/dispatch/location", { id: location_id, "updating_stop": true }).then(rowLocation => {
                    this.setDriverUnloadTextAfterLookup(new ModelRow(this.textboxDriverLoadUnload.lookupModel, false, this.getDriverLoadUnloadData(rowLocation)));
                });
            }
            EdiUtility.checkDFXref(
                this.textboxLocationCode.text,
                this.textboxEdiLocCode.text,
                this.sourceEdiStop,
                this.orderSource,
                this.dfXref,
                this.owner,
                this.stopSource,
                true
            );
            //this.calculateRates();
            return undefined;
        });

        //this.textboxSchedArriveEarly.addlValidationCallback = () => this.scheduleArriveValidator();
        this.textboxSchedArriveEarly.addBlurListener(event => this.stopDateOnBlur(event));
        this.textboxSchedArriveLate.addBlurListener(event => this.stopDateOnBlur(event));
        this.ediCityState.enabled = false;
        this.ediCityState.textCombined.onSelectItem = () => {
            //this.calculateRates();
            return undefined;
        }
        //this.switchPickupConsignee.addChangeListener((event) => { if (event.userInitiatedChange) this.calculateRates() });
    }

    /** This is an event handler for the onBlur event of textboxSchedArriveLate and textboxSchedArriveEarly.  */
    stopDateOnBlur(event: BlurEvent) {
        const textbox = event.target as Textbox;
        if (textbox.isDropdownVisible()) return;
        // checkAppointment(event, this)
        textbox._input.value = textbox.text; // this is a workaround for an issue where the input value is not updated after a change as a result of a validation warning
    }

    setLocationTextAfterLocation(textBox: Textbox, row: ModelRow, locationIdField?: string) {
        const data = row?.data
        const source = row?.data["source_name"];

        if (locationIdField == null)
            locationIdField = "id";

        if (source && source == "CITY") {
            textBox.text = CityUtil.formatCityStateZip(data?.["city_name"], data?.["state"], data?.["zip_code"]);
        }
        else {
            this.textboxLocationCode.text = data?.[locationIdField];
            this.textboxLocationCode.tooltipCallback = makeTooltipCallbackFunction(data?.[locationIdField], this.textboxLocationCode);
            this.textboxLocationName.displayData(this.sourceEdiStop.activeRow, null, 0);
            this.textboxAddress.displayData(this.sourceEdiStop.activeRow, null, 0);
            this.textboxAddress2.displayData(this.sourceEdiStop.activeRow, null, 0);
        }
        this.ediCityState.displayData(this.sourceEdiStop.activeRow, null, 0);
    }

    private setApptRequired(rowLocation: ModelRow<any>) {
        this.mainDataSource.activeRow.set("appt_required", rowLocation?.get("appt_required"));
        this.switchApptRequired.displayData(this.mainDataSource.activeRow, null, 0);
    }

    initialize(isSlideIn: boolean, stopIndex: number, orderDataSource: DataSource, stopDataSource?: DataSource) {
        this.layoutStopShowAsButton.orderSource = orderDataSource;
        this.panelAdditionalDetails.visible = isSlideIn;
        const isPickupStop = stopIndex == 0;

        if (!isSlideIn) {
            const tableRow: TableRow = TableRow.getContainingTableRow(this);
            this.layoutStopShowAsButton.ediStopRow = tableRow.data;
            if (tableRow != null) {
                this.mainDataSource.setRowsAndMode(orderDataSource.mode, [tableRow.data]);
            }
            this.setTableParentDataSource(this.commentsAndRefNbrs.tableStopComments, orderDataSource);
            this.setTableParentDataSource(this.commentsAndRefNbrs.tableStopReferenceNumbers, orderDataSource);
        }
        this.textboxLocationId.textCombined.manualAddLayout = null;
        if (this.sourceEdiStop.activeRow != null) {
            this.textboxLocationCode.text = this.sourceEdiStop.activeRow.get("location_id");
            this.textboxLocationCode.tooltipCallback = makeTooltipCallbackFunction(this.textboxLocationCode.text, this.textboxLocationCode);
        }

        if (isPickupStop) {
            this.labelPickup.visible = true;
            this.switchPickupConsignee.visible = false;
            this.textboxLocationId.visible = true;
            this.textboxLocationId.required = this.enforceShipper;

            this.switchPalletsRequired.visible = true;
            this.textboxPalletsHowMany.visible = "Y" === (orderDataSource?.activeRow != null && orderDataSource?.activeRow.get("pallets_required"));
            if (isSlideIn) {
                this.switchPalletsRequired.checked = this.textboxPalletsHowMany.visible;
                if (orderDataSource?.activeRow?.get("pallets_how_many") != null)
                    this.textboxPalletsHowMany.text = orderDataSource?.activeRow?.get("pallets_how_many").toString();
            } else {
                this.textboxPalletsHowMany.dataSource = orderDataSource;
                this.switchPalletsRequired.dataSource = orderDataSource;
            }
        }

        this.textboxLocationId.googlePlacesProps = {
            enableSearch: getDispatchControlBoolean("enable_google_places"),
            createLocations: getDispatchControlBoolean("create_location_from_stop"),
            customerId: "",
            doBeforeGoogleLocationCreated: () => this.labelCreatingLocation.visible = true,
            doAfterGoogleLocationCreated: () => this.labelCreatingLocation.visible = false
        };

        if (!orderDataSource?.activeRow?.isNull("customer_id"))
            this.textboxLocationId.googlePlacesProps.customerId = orderDataSource?.activeRow?.get("customer_id");

        this.commentsAndRefNbrs.stopRow = this.mainDataSource.activeRow;
        this.addFieldUpdateListener(this.mainDataSource.activeRow);

        if (!this.mainDataSource.activeRow._appending) {
            const selectedItem = this.mainDataSource.activeRow?.get("sched_arrive_late") != null ? DATE_BETWEEN : DATE_ON;
            this.textboxStopDateType.selectedItem = selectedItem;
            if (PermissionsUtil.isUserDeniedAction("Dispatch.Unconfirm appointments") && this.activeRow.getBoolean("confirmed", false)) {
                this.switchConfirmed.enabled = false;
                this.switchConfirmed.disabledTooltip = "You do not have permission to unconfirm appointments";
            }
        }
        const updatingOrder = DataSourceMode.UPDATE === orderDataSource?.mode;
        if (updatingOrder && this.mainDataSource.activeRow?.get("sched_arrive_late") == null)
            this.textboxStopDateType.selectedItem = this.stopDateTypeOn;
        this.validateApptChanges = updatingOrder && this.sourceEdiStop.activeRow._appending == false;
        this.orderSource = orderDataSource;
        this.stopSource = stopDataSource;
        EdiUtility.setXref(this.mainDataSource.activeRow, this.dfXref);
    }

    addFieldUpdateListener(row: ModelRow<any>) {
        if (row["_stopListener"] == null) { // keep a reference in each row to the listener to prevent adding multiple listeners if the same row is used somehow
            row["_stopListener"] = (event: FieldUpdateEvent) => {
                if (event.oldValue != event.newValue) {
                    if (event.fieldName === "location_id") {
                        this.doAfterLocationIdChanged();
                    } else if (event.fieldName === "city_id") {
                        if (event.newValue == null) {
                            row.setLookupModelData("zone_id", null);
                            row.set("zone_id", null);
                            //this.textboxZoneId.displayData(row, null, 0);
                        } //else {
                        //   new ModelZones().searchSingle({ city_id: event.newValue }).then(response => {
                        //     if(response != null){
                        //       const lmModelRow = new ModelRow(this.textboxZoneId.lookupModel, false, {id:response.get("id"), descr:response.get("descr")});
                        //       lmModelRow.type = ModelRowType.LOOKUP_MODEL_DATA;
                        //       row.setLookupModelData("zone_id", lmModelRow);
                        //       row.set("zone_id", response.get("id"));
                        //       this.textboxZoneId.displayData(row, null, 0);
                        //     }
                        //   });
                        // }
                    }
                    if (event.fieldName == "sched_arrive_late") {
                        if (event.newValue != null && event.oldValue == null)
                            this.textboxStopDateType.selectedItem = this.stopDateTypeBetween;
                        else if (event.newValue == null && event.oldValue != null)
                            this.textboxStopDateType.selectedItem = this.stopDateTypeOn;
                    }
                }
            }
            row.addAfterFieldUpdateListener(row["_stopListener"]);
        }
    }

    doAfterLocationIdChanged() {
        const location_id = this.mainDataSource.activeRow?.get("location_id")
        if (location_id) {
            Model.searchSingleRecord("lme/dispatch/location", { id: location_id, "updating_stop": true }).then(rowLocation => {
                if (this.loadingRecurringOrder && this.orderSource?.activeRow?.isNull("recurring_order_id"))
                    this.setApptRequired(rowLocation);
                this.textboxStopDateType.selectedItem = this.activeRow.get("sched_arrive_late") != null ? DATE_BETWEEN : DATE_ON;
                this.textboxLocationId.textCombined.tooltipCallback = makeTooltipCallbackFunction(location_id, this.textboxLocationId.textCombined);
            });
        }
        this.setStopContactInfo();
    }

    initShipperStop(value: boolean) {
        if (value) {
            this.labelPickup.visible = true;
            this.switchPickupConsignee.visible = false;
            this.textboxLocationId.visible = true;

            this.textboxLocationId.textCombined.manualAddLayout = null;
        }
    }

    setTableParentDataSource(table: Table, parentSource: DataSource) {
        if (parentSource != null) {
            table.dataSource.parentDataSource = parentSource;
            const mode = parentSource.mode;
            //if we are setting the mode to ADD, we don't need the blank/empty ModelRow that setting the mode normally will create
            //the fact that the creation of the blank row is async can only mess us up; it can cause existing rows to be wiped out
            //when the blank row is done creating (which causes the table's data to be redisplayed)
            if (mode !== DataSourceMode.ADD)
                table.dataSource.mode = mode;
            else
                table.dataSource.setRowsAndMode(mode, []);
        }
    }

    switchPickupConsigneeOnChange(event) {
        this.setStopDateCaptions(this.switchPickupConsignee.checked);
        const location_id = this.mainDataSource.activeRow?.get("location_id")
        if (location_id) {
            Model.searchSingleRecord("lme/dispatch/location", { id: location_id, "updating_stop": true }).then(rowLocation => {
                if (rowLocation != null)
                    this.setDriverUnloadTextAfterLookup(new ModelRow(this.textboxDriverLoadUnload.lookupModel, false, this.getDriverLoadUnloadData(rowLocation)));
            });
        }
    }

    setStopDateCaptions(isDelivery: boolean) {
        const caption = isDelivery ? "Delivery" : "Pickup";
        const isDateWindow = this.textboxStopDateType.selectedItem == DATE_BETWEEN;

        this.textboxStopDateType.caption = caption;
        this.textboxSchedArriveEarly.caption = isDateWindow ? `${caption} Window` : `${caption} Appointment`;
    }

    getDriverLoadUnloadData(rowLocation: RowLocation): any {
        const loadUnloadField = this.isDelivery() ? "driver_unload_id" : "driver_load_id";
        let loadUnloadModelRowData = rowLocation.getFirstLookupModelData(loadUnloadField);
        if (loadUnloadModelRowData == null)
            loadUnloadModelRowData = { descr: 'No driver loading or unload', id: 'N' };
        else if (loadUnloadModelRowData instanceof ModelRow)
            loadUnloadModelRowData = loadUnloadModelRowData.data;
        return loadUnloadModelRowData;
    }

    setDriverUnloadTextAfterLookup(driverUnloadLookupData: ModelRow) {
        this.mainDataSource.activeRow.setLookupModelData("driver_load_unload", driverUnloadLookupData);
        this.mainDataSource.activeRow.set("driver_load_unload", driverUnloadLookupData.get("id"));
        if (this.mainDataSource.activeRow._appending)
            this.textboxDriverLoadUnload.displayData(this.mainDataSource.activeRow, null, 0);
    }

    switchApptRequiredOnChange(event) {
        this.switchConfirmed.visible = this.switchApptRequired.checked;
    }

    switchPalletsRequiredOnChange(event) {
        this.textboxPalletsHowMany.visible = this.switchPalletsRequired.visible && this.switchPalletsRequired.checked;
    }

    isDelivery(): boolean {
        return this.switchPickupConsignee.visible && this.switchPickupConsignee.checked;
    }

    getData(): ModelRow<any> {
        return TableRow.getContainingTableRow(this)?.data;
    }

    /** This is an event handler for the onChange event of textboxStopDateType.  */
    textboxStopDateTypeOnChange(event: ChangeEvent) {
        const between = event.newValue === DATE_BETWEEN.caption;
        this.panelLateArrival.visible = between;
        this.textboxSchedArriveLate.required = false;
        if (between) {
            this.textboxSchedArriveLate["_captionLabel"].preventCollapse = true;
            this.textboxSchedArriveLate["_captionLabel"].visible = false;
            this.textboxSchedArriveLate.required = true;
            this.textboxSchedArriveLate.placeholder = "Required";
        } else if (event.userInitiatedChange) {
            this.mainDataSource.activeRow?.set("sched_arrive_late", null);
            //this.textboxSchedArriveEarly.validateSimple(false, true);
        }
        this.setStopDateCaptions(this.isDelivery());
        if (this.mainDataSource.activeRow != null) {
            this.mainDataSource.activeRow["stop_date_type"] = this.textboxStopDateType.selectedItem;
        }
    }

    private setStopContactInfo() {
        const location_id = this.mainDataSource.activeRow.get("location_id")
        if (!location_id) {
            this.mainDataSource.activeRow.setLookupModelData("contact_name", null);
            this.mainDataSource.activeRow.set({ "contact_name": null, "phone": null, "contact_email": null });
            this.textboxContactName.displayData(this.mainDataSource.activeRow, null, 0);
            this.textboxPhone.displayData(this.mainDataSource.activeRow, null, 0)
            this.textboxContactEmail.displayData(this.mainDataSource.activeRow, null, 0)
        } else {
            new ModelContact().searchSingle({ "parent_row_type": "L", "parent_row_id": location_id }).then(result => {

                const contactData = result?.data;
                if (!contactData)
                    return;

                this.mainDataSource.activeRow.setLookupModelData("contact_name", contactData);

                if (contactData["name"]) {
                    this.mainDataSource.activeRow.set("contact_name", contactData["name"]);
                    this.textboxContactName.validate(false, false);
                    this.textboxContactName.displayData(this.mainDataSource.activeRow, null, null);
                }

                if (contactData["phone"]) {
                    this.mainDataSource.activeRow.set("phone", contactData["phone"]);
                    this.textboxPhone.validate(false, false);
                    this.textboxPhone.displayData(this.mainDataSource.activeRow, null, null);
                }

                if (contactData["email"]) {
                    this.mainDataSource.activeRow.set("contact_email", contactData["email"]);
                    this.textboxContactEmail.validate(false, false);
                    this.textboxContactEmail.displayData(this.mainDataSource.activeRow, null, null);
                }
            });
        }

    }

    textboxContactNameBeforeLookupModelSearch(event) {
        if (this.mainDataSource.activeRow.get("location_id")) {
            event.filter.parent_row_type = "L";
            event.filter.parent_row_id = this.mainDataSource.activeRow.get("location_id");
            event.filter.is_active = "Y";
        } else {
            event.filter.id = null;
        }
    }

    setFreeFormVisibility() {
        const locationIdExists: boolean = this.mainDataSource.activeRow.get("location_id") == null;
        this.ediCityState.enabled = locationIdExists;
        this.ediCityState.enabledDuringAdd = locationIdExists;
        this.ediCityState.enabledDuringUpdate = locationIdExists;
    }
    /** This is an event handler for the onChange event of switchConfirmed.  */
    switchConfirmedOnChange(event: ChangeEvent) {
        const changedData = this.sourceEdiStop.activeRow?.getChangedData();
        if (event.userInitiatedChange && this.validateApptChanges
            && changedData["confirmed"] != null && changedData["confirmed"] === "N") {
            showAppointmentChangeDialog({ "confirmed": "Y" }, this.sourceAppointmentChange, this.mainDataSource.activeRow, { switchConfirmed: this.switchConfirmed }).then(() => {
                this.switchApptRequired.enabled = !this.activeRow?.getBoolean("confirmed");
            });
        } else {
            this.switchApptRequired.enabled = !this.activeRow?.getBoolean("confirmed");
        }
    }

    getAppointmentChangedRow(): ModelRow<any> {
        const changedValues = this.mainDataSource.activeRow.getChangedData();
        if (this.apptChanged(changedValues)) {
            const row = this.mainDataSource.activeRow.get("appointment_change", null);
            if (row != null) {
                row.set("stop_id", this.sourceEdiStop.activeRow?.get("id"));
                row.set("sequence", this.sourceEdiStop.activeRow?.get("order_sequence"));
                if (changedValues["sched_arrive_early"] != null)
                    row.set("revised_early", this.sourceEdiStop.activeRow?.get("sched_arrive_early"));
                if (changedValues["sched_arrive_late"] != null)
                    row.set("revised_late", this.sourceEdiStop.activeRow?.get("sched_arrive_late"));
            }
            return row;
        }
    }

    private apptChanged(changedData: any) {
        return this.validateApptChanges && this.mainDataSource.activeRow.get("appointment_change", null) != null &&
            ((changedData["confirmed"] != null && changedData["confirmed"] === "N") ||
                (isApptConfirmed(this.sourceEdiStop.activeRow) && (changedData["sched_arrive_early"] != null || changedData["sched_arrive_late"] != null)))
    }

    /** This is an event handler for the afterModeChange event of sourceStop.  */
    sourceStopAfterModeChange(event) {
        this.validateApptChanges = event.newMode === DataSourceMode.UPDATE;
    }

    private setTimezone(comp: Textbox) {
        const abbr = this.sourceEdiStop.activeRow?.get("timezone_id");
        comp.timezone = Timezone.getFromAbbreviation(abbr);
    }

    /** This is an event handler for the onDataDisplay event of citystate.  */
    citystateOnDataDisplay(event: DataDisplayEvent) {
        this.setTimezone(this.textboxSchedArriveEarly);
        this.setTimezone(this.textboxSchedArriveLate);
    }

    scheduleArriveValidator(): ValidationResult {
        // if (!StringUtil.isEmptyString(this.textboxSchedArriveEarly.text) && !StringUtil.isEmptyString(this.textboxSchedArriveLate.text)) {
        //   const arrivalDate = DateUtil.parseDateWithKeywords(this.textboxSchedArriveEarly.text, true, true);
        //   const departureDate = DateUtil.parseDateWithKeywords(this.textboxSchedArriveLate.text, true, true);
        //   const dayDiff = DateUtil.dateDiff(DatePart.SECOND, departureDate, arrivalDate);

        //   if (dayDiff < 0) {
        //     const validDate = TextboxValidator.validate(this.textboxSchedArriveEarly, true, true, null);
        //     this.textboxSchedArriveEarly.text = validDate.isValid ? validDate.validatedValue : this.textboxSchedArriveEarly.text;
        //     return { component: this.textboxSchedArriveEarly, isValid: false, validationMessage: "The early date cannot be later than the late date." };
        //   }
        // }

        return null;
    }

    override displayData(data: ModelRow, allData: ModelRow[], rowIndex: number) {
        super.displayData(data, allData, rowIndex);
        // When a stop is deleted from the Orders page, each row in the stops table is rebuilt and
        // textboxStopDateType.selectedItem selected by the user is wiped out.
        const stopType = this?.activeRow?.["stop_date_type"];
        if (stopType != null) {
            this.textboxStopDateType.selectedItem = stopType;
        }
    }

    onComponentValidationFailed(result: ValidationResult) {
        const comp = result.component;
        const stopPrefix = this.getStopPrefix();
        result.caption = stopPrefix + result.caption;
        if (comp == this.textboxSchedArriveLate) {
            if (this.textboxSchedArriveEarly.validationWarning) {
                // "Pickup/Delivery Window" will already be displayed as missing or invalid field
                result.caption = null;
            } else {
                result.caption = stopPrefix + this.textboxSchedArriveEarly.caption;
            }
        } else {

            if (comp == this.ediCityState && this.textboxLocationId.required) {
                result.caption = null;
            } else {
                if (comp.parent == this.panelStopFreeForm && !this.panelStopFreeForm.visible)
                    result.componentToFocus = this.textboxLocationId;
            }
        }
    }

    getStopPrefix() {
        const tableRow = TableRow.getContainingTableRow(this);
        if (tableRow != null) {
            if (tableRow.index == 0)
                return "Origin ";
            else if (tableRow.index == tableRow.table.rowCount - 1)
                return "Destination ";
            return `Stop ${(tableRow.index + 1)} `;
        }
        return "";
    }

    async dfXrefOnClick(event: ClickEvent) {
        EdiUtility.dfXrefOnClick(this.sourceEdiStop, this.orderSource, this.dfXref, this.owner);
    }

    textboxLocationCodeOnChange(event: ChangeEvent) {
        log.info("textboxLocationCodeOnChange");
        if (this.mainDataSource.activeRow != null) {
            const currRow = this.mainDataSource.activeRow;
            log.info("Changed location id to " + currRow.get("location_id"));
        }
    }

    async ediLocCodeOnBlur(event: BlurEvent) {
        const textBox = event.target as Textbox;
        const tableRow = TableRow.getContainingTableRow(textBox);
        await EdiUtility.setXref(tableRow.data, this.dfXref);
    }

    public get orderSource(): DataSource {
        return this._orderSource;
    }

    public set orderSource(value: DataSource) {
        this._orderSource = value;
    }

    public get stopSource(): DataSource {
        return this._stopSource;
    }

    public set stopSource(value: DataSource) {
        this._stopSource = value;
    }

    public get profileId(): string {
        return this._profileId;
    }

    public set profileId(value: string) {
        this._profileId = value;
    }
}
