import { CommonDialogs } from "@mcleod/common";
import { DataSource, Label, Snackbar } from "@mcleod/components";
import { Api, McLeodClassicIntegration, ModelRow, StringUtil } from "@mcleod/core";
import { ModelEdiorderProfile } from "./models/ModelEdiorderProfile";
import { ModelEdilocation } from "./models/ModelEdilocation";

export class EdiUtility {
    public static getErrorProps() {
        return {
            imageName: "circleX",
            imageColor: "error"
        };
    }

    public static getSuccessProps() {
        return {
            imageName: "circleCheck",
            imageColor: "success"
        };
    }

    public static openClassicPartnerProfile(id: string, classicScreen: string) {
        McLeodClassicIntegration.openClassicScreen(classicScreen, "<ext> search id='" + id + "'");
    }

    public static async promptForXref(mainDataSource: DataSource<ModelRow<any>>, orderSource: DataSource<ModelRow<any>>, stopSource: DataSource<ModelRow<any>>, isEdiStop: boolean = false): Promise<any> {
        const stopId = mainDataSource.data[0].data["id"];
        const stopType = mainDataSource.data[0].data["stop_type"];
        const orderId = orderSource.data[0].data["id"];
        const shipId = stopSource?.data[0]?.data["id"];
        const consId = stopSource?.data[stopSource?.data?.length -1]?.data["id"];
        let profileId = orderSource["ediProfileId"];
        if (StringUtil.isEmptyString(profileId)) {
            const partner = orderSource.data[0].data["partner_id"];
            const version = isEdiStop ? orderSource.data[0].data["version"] : orderSource.data[0].data["partner_version"];
            if (!StringUtil.isEmptyString(partner) && !StringUtil.isEmptyString(version)) {
                const profile = await new ModelEdiorderProfile().searchSingle({
                    partner_id: partner,
                    version: version
                });
                profileId = profile?.data?.id;
            }
        }

        return Api.search("lme/datafusion/xref-profile", {
            stop_id: stopId,
            stop_type: stopType,
            order_id: orderId,
            profile_id: profileId,
            cons_id: consId,
            ship_id: shipId,
            is_edi_stop: isEdiStop ? "Y" : "N"
        });
    }

    public static async addDataFusionCrossReference(partnerId: string, version: string, locId: string, ediLoc: string): Promise<any> {
        return Api.post("lme/datafusion/add-location-xref", {
            "partner_id": partnerId,
            "version": version,
            "edi_location": ediLoc,
            "location_id": locId
        });
    }

    public static returnYesNoDialogForExistingReference(ediLocationFromRow: string, locId: string, ediLoc: string) {
        return CommonDialogs.showYesNo(`Cross reference exists for partner code ${ediLocationFromRow} and location ${locId} on the corresponding DataFusion profile.  Would you still like to add this cross reference (${ediLoc}/${locId})?`,
            "Add DataFusion Cross Reference",
            { titleProps: { imageName: "circleX2", imageColor: "primary.light" } });
    }

    public static returnYesNoDialogForExistingReferenceUpdate(ediLocationFromRow: string, locId: string, ediLoc: string) {
        return CommonDialogs.showYesNo(`Cross reference exists for partner code ${ediLoc} and location ${ediLocationFromRow} on the corresponding DataFusion profile.  Would you like to update this cross reference (${ediLoc}/${locId})?`,
            "Add DataFusion Cross Reference",
            { titleProps: { imageName: "circleX2", imageColor: "primary.light" } });
    }

    public static returnYesNoDialogForAddingCrossReference(ediLoc: string, locId: string) {
        return CommonDialogs.showYesNo(`Would you like to add a cross reference for partner code ${ediLoc} and location ${locId} on the corresponding DataFusion profile(s)?`,
            "Add DataFusion Cross Reference",
            { titleProps: { imageName: "circleX2", imageColor: "primary.light" } });
    }

    public static setLabelXref(labelXref: Label, xrefExists: boolean) {
        if (xrefExists) {
            labelXref.visible = true;
            labelXref.imageName = "linked";
            labelXref.imageColor = "default.darkest";
            labelXref.tooltip = "Location cross reference successfully applied";
        } else {
            labelXref.visible = true;
            labelXref.imageName = "notLinked";
            labelXref.imageColor = "default.darkest";
            labelXref.tooltip = "Location is missing a cross reference to DataFusion location.";
        }
    }

    public static async setXref(currentRow: ModelRow, dfXref: Label) {
        if (!StringUtil.isEmptyString(currentRow.get("edi_loc_code"))) {
            if (!StringUtil.isEmptyString(currentRow.get("location_id"))) {
                const edilocRow = await new ModelEdilocation().searchSingle({
                    location_id: currentRow.get("location_id"),
                    edi_location: currentRow.get("edi_loc_code")
                });
                EdiUtility.setLabelXref(dfXref, edilocRow == null ? false : true);
            }
            else {
                EdiUtility.setLabelXref(dfXref, false);
            }
        }
        else {
            dfXref.visible = false;
        }
    }

    public static async checkDFXref(locId: string, ediLoc: string, mainDataSource: DataSource<ModelRow<any>>, orderSource: DataSource<ModelRow<any>>, xrefLabel: Label, owner: any, stopSource: DataSource<ModelRow<any>>, isEdiStop: boolean = false) {
        let edilocRow = null;
        const partnerFromOrderSource = orderSource.data[0].data["partner_id"];
        const versionFromOrderSource = isEdiStop ? orderSource.data[0].data["version"] : orderSource.data[0].data["partner_version"];
        if (!StringUtil.isEmptyString(ediLoc)
            && !StringUtil.isEmptyString(locId)
            && !StringUtil.isEmptyString(partnerFromOrderSource)
            && !StringUtil.isEmptyString(versionFromOrderSource)) {
            edilocRow = await new ModelEdilocation().searchSingle({ location_id: locId, edi_location: ediLoc, partner_id: partnerFromOrderSource, version: versionFromOrderSource });
        }

        if (edilocRow == null) {
            EdiUtility.promptForXref(mainDataSource, orderSource, stopSource, isEdiStop).then(async result => {
                const needsPrompt: boolean = result.data[0].needs_xref_prompt == "Y" ? true : false;
                const partnerId: string = result.data[0].partner_id;
                const version: string = result.data[0].version;
                const edilocRow2 = await new ModelEdilocation().searchSingle({ location_id: locId, partner_id: partnerId, version: version });  //xref exists for same location id if present
                const edilocRow3 = await new ModelEdilocation().searchSingle({ edi_location: ediLoc, partner_id: partnerId, version: version });  //xref exists for same edi location if present

                //Duplicate checks - allow for multiple partner codes to xref to same location_id, but do not allow the same partner code to point to multiple location_ids
                if (needsPrompt && edilocRow2 != null) {
                    //Check for multiple xref for same LME location id - warn but allow multiples
                    if (edilocRow2.get("edi_location") == ediLoc) {
                        return;
                    }
                    else if (!StringUtil.isEmptyString(edilocRow2.get("edi_location")) && !StringUtil.isEmptyString(locId) && !StringUtil.isEmptyString(ediLoc)) {
                        EdiUtility.returnYesNoDialogForExistingReference(edilocRow2.get("edi_location"), locId, ediLoc).then(yes => {
                            if (yes) {
                                //add additional xref to LME location id
                                return EdiUtility.addDataFusionCrossReference(partnerId, version, locId, ediLoc);
                            }
                            else {
                                return;
                            }
                        });
                    }
                }
                else if (needsPrompt && edilocRow3 != null) {
                    //Check for multiple xref for same partner code - only allow update
                    if (edilocRow3.get("location_id") == locId) {
                        return "Record Exists";
                    }
                    else if (!StringUtil.isEmptyString(edilocRow3.get("location_id")) && !StringUtil.isEmptyString(locId) && !StringUtil.isEmptyString(ediLoc)) {
                        EdiUtility.returnYesNoDialogForExistingReferenceUpdate(edilocRow3.get("location_id"), locId, ediLoc).then(yes => {
                            if (yes) {
                                //update existing xref - update edi_location
                                edilocRow3.set("location_id", locId);
                                edilocRow3.post();
                            }
                            else {
                                return "Record Exists";
                            }
                        });
                    }
                }
                else if (needsPrompt) {
                    if (!StringUtil.isEmptyString(ediLoc)) {
                        if (!StringUtil.isEmptyString(locId)) {
                            CommonDialogs.showYesNo("Would you like to add a cross reference for partner code " + ediLoc + " and location " + locId +
                                " on the corresponding DataFusion profile(s)?", "Add DataFusion Cross Reference",
                                { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(async yes => {
                                    if (yes) {
                                        EdiUtility.addDataFusionCrossReference(partnerId, version, locId, ediLoc).then(result => {
                                            const recAdded = result.data[0].result;

                                            if (recAdded == "Record Added") {
                                                Snackbar.showSnackbar("Successfully added cross reference for " + ediLoc + " and " + locId + ".", { targetPanel: owner });
                                                EdiUtility.setLabelXref(xrefLabel, true);
                                            }
                                            else {
                                                Snackbar.showWarningSnackbar("Unable to add cross reference for " + ediLoc + " and " + locId + ".", { targetPanel: owner });
                                                EdiUtility.setLabelXref(xrefLabel, false);
                                            }
                                        });
                                    }
                                    else {
                                        EdiUtility.setLabelXref(xrefLabel, false);
                                    }
                                });
                        }
                        else {
                            EdiUtility.setLabelXref(xrefLabel, false);
                        }
                    }
                    else if (!StringUtil.isEmptyString(locId) && !StringUtil.isEmptyString(ediLoc)) {
                        EdiUtility.setLabelXref(xrefLabel, edilocRow == null ? false : true);
                    }
                }
            });
        }
        else {
            EdiUtility.setLabelXref(xrefLabel, true);
        }
    }

    public static async dfXrefOnClick(mainDataSource: DataSource<ModelRow<any>>, orderSource: DataSource<ModelRow<any>>, dfXref: Label, owner: any, stopSource?: DataSource<ModelRow<any>>) {
        const locId = mainDataSource.data[0].data["location_id"];
        const ediLoc = mainDataSource.data[0].data["edi_loc_code"];
        const partnerFromOrderSource = orderSource.data[0].data["partner_id"];
        const versionFromOrderSource = orderSource.data[0].data["version"] ?? orderSource.data[0].data["partner_version"];

        const edilocRow = await new ModelEdilocation().searchSingle({ location_id: locId, edi_location: ediLoc, partner_id: partnerFromOrderSource, version: versionFromOrderSource });
        if (edilocRow != null
            && edilocRow.get("location_id") == locId
            && edilocRow.get("edi_location") == ediLoc
            && edilocRow.get("partner_id") == partnerFromOrderSource
            && edilocRow.get("version") == versionFromOrderSource
        ) {
            EdiUtility.setLabelXref(dfXref, true);
            return;
        }

        EdiUtility.promptForXref(mainDataSource, orderSource, stopSource, true).then(async result => {
            const partnerId: string = result.data[0].partner_id;
            const version: string = result.data[0].version;

            const labelXref = dfXref;

            if (!StringUtil.isEmptyString(locId) && !StringUtil.isEmptyString(ediLoc)) {
                const edilocRow2 = await new ModelEdilocation().searchSingle({ location_id: locId, partner_id: partnerId, version: version });  //xref exists for same location id if present
                const edilocRow3 = await new ModelEdilocation().searchSingle({ edi_location: ediLoc, partner_id: partnerId, version: version });  //xref exists for same edi location if present

                //Duplicate checks - allow for multiple partner codes to xref to same location_id, but do not allow the same partner code to point to multiple location_ids
                if (edilocRow2 != null) {
                    //Check for multiple xref for same LME location id - warn but allow multiples
                    if (edilocRow2.get("edi_location") == ediLoc) {
                        return;
                    }
                    else if (!StringUtil.isEmptyString(edilocRow2.get("edi_location")) && !StringUtil.isEmptyString(locId) && !StringUtil.isEmptyString(ediLoc)) {
                        EdiUtility.returnYesNoDialogForExistingReference(edilocRow2.get("edi_location"), locId, ediLoc).then(yes => {
                            if (yes) {
                                //add additional xref to LME location id
                                return EdiUtility.addDataFusionCrossReference(partnerId, version, locId, ediLoc).then(() => {
                                    EdiUtility.setLabelXref(dfXref, true);
                                });

                            }
                            else {
                                return;
                            }
                        });
                    }
                }
                else if (edilocRow3 != null) {
                    //Check for multiple xref for same partner code - only allow update
                    if (edilocRow3.get("location_id") == locId) {
                        return;
                    }
                    else if (!StringUtil.isEmptyString(edilocRow3.get("location_id")) && !StringUtil.isEmptyString(locId) && !StringUtil.isEmptyString(ediLoc)) {
                        EdiUtility.returnYesNoDialogForExistingReferenceUpdate(edilocRow3.get("location_id"), locId, ediLoc).then(yes => {
                            if (yes) {
                                //update existing xref - update edi_location
                                edilocRow3.set("location_id", locId);
                                edilocRow3.post();
                                EdiUtility.setLabelXref(dfXref, true);
                            }
                            else {
                                return "Record Exists";
                            }
                        });
                    }
                }
                else {
                    EdiUtility.returnYesNoDialogForAddingCrossReference(ediLoc, locId).then(async yes => {
                        if (yes) {
                            if (!StringUtil.isEmptyString(locId) && !StringUtil.isEmptyString(ediLoc)) {
                                EdiUtility.addDataFusionCrossReference(partnerId, version, locId, ediLoc).then(result => {
                                    const recAdded = result.data[0].result;

                                    if (recAdded == "Record Added") {
                                        Snackbar.showSnackbar("Successfully added cross reference for " + ediLoc + " and " + locId + ".", { targetPanel: owner });
                                        EdiUtility.setLabelXref(dfXref, true);
                                    }
                                    else {
                                        Snackbar.showWarningSnackbar("Unable to add cross reference for " + ediLoc + " and " + locId + ".", { targetPanel: owner });
                                        EdiUtility.setLabelXref(dfXref, false);
                                    }
                                });
                            }
                            else {
                                labelXref.visible = false;
                            }
                        }
                    });
                }
            }
            else {
                Snackbar.showWarningSnackbar("Location ID and DataFusion partner code must be present to create a cross reference.")
            }
        });
    }
}